import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';

import { injectIntl, intlShape, FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';

import { AspectRatioWrapper, ResponsiveImage } from '../../../components';

import css from './SearchMapInfoCard.module.css';

// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = props => {
  const { className, clickHandler, intl, isInCarousel, listing, urlToListing, config } = props;

  const { title, price } = listing.attributes;

  //tile
  const titleFirstPart = title.split('•')[0];
  // roomType
  const roomType = listing?.attributes?.publicData?.roomtype;

  //is Experience
  const experienceType = title.split('• ')[1];
  const experienceTitle =
    experienceType === '2 Days 1 Night'
      ? intl.formatMessage({ id: 'experienceTitle.2d1n' })
      : experienceType === '3 Days 2 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.3d2n' })
      : experienceType === '4 Days 3 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.4d3n' })
      : experienceType === '5 Days 4 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.5d4n' })
      : experienceType === '6 Days 5 Nights'
      ? intl.formatMessage({ id: 'experienceTitle.6d5n' })
      : experienceType === '1 Week'
      ? intl.formatMessage({ id: 'experienceTitle.1w' })
      : experienceType === '2 Weeks'
      ? intl.formatMessage({ id: 'experienceTitle.2w' })
      : experienceType === '3 Weeks'
      ? intl.formatMessage({ id: 'experienceTitle.3w' })
      : null;

  // function to get roomType
  const titleSecondpart =
    roomType === 'singlebedroom'
      ? intl.formatMessage({ id: 'roomtypes.singlebedroom' })
      : roomType === 'doublebedroom'
      ? intl.formatMessage({ id: 'roomtypes.doublebedroom' })
      : roomType === 'twobedroom'
      ? intl.formatMessage({ id: 'roomtypes.twobedroom' })
      : roomType === 'shared_bedroom'
      ? intl.formatMessage({ id: 'roomtypes.shared_bedroom' })
      : roomType === 'multi_bedroom'
      ? intl.formatMessage({ id: 'roomtypes.multi_bedroom' })
      : roomType === 'entire_accomodation'
      ? intl.formatMessage({ id: 'roomtypes.entire_accomodation' })
      : roomType === 'tent'
      ? intl.formatMessage({ id: 'roomtypes.tent' })
      : experienceTitle
      ? experienceTitle
      : 'not found';
  const newTitle = titleFirstPart + ' • ' + titleSecondpart;

  const formattedPrice =
    price && price.currency === config.currency ? formatMoney(intl, price) : price.currency;
  const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [css.borderRadiusInheritBottom]: !isInCarousel },
    className
  );
  let rmvChars = formattedPrice.replace(/[^0-9\.,]/g, '');
  let replaceX = rmvChars.replace(',', '.');
  let roundedPrice = Math.ceil(replaceX);
  let finalPrice = `${roundedPrice}€`;
  return (
    <a
      alt={title}
      className={classes}
      href={urlToListing}
      onClick={e => {
        e.preventDefault();
        // Use clickHandler from props to call internal router
        clickHandler(listing);
      }}
    >
      <div
        className={classNames(css.card, css.borderRadiusInheritTop, {
          [css.borderRadiusInheritBottom]: !isInCarousel,
        })}
      >
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
        >
          <ResponsiveImage
            rootClassName={classNames(css.rootForImage, css.borderRadiusInheritTop)}
            alt={title}
            noImageMessage={intl.formatMessage({ id: 'SearchMapInfoCard.noImage' })}
            image={firstImage}
            variants={variants}
            sizes="250px"
          />
        </AspectRatioWrapper>
        <div className={classNames(css.info, { [css.borderRadiusInheritBottom]: !isInCarousel })}>
          <div className={css.price}>{finalPrice}</div>
          <div className={css.name}>{newTitle}</div>
        </div>
      </div>
    </a>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  clickHandler: func.isRequired,
  intl: intlShape.isRequired,
  isInCarousel: bool.isRequired,
};

const SearchMapInfoCard = props => {
  const [currentListingIndex, setCurrentListingIndex] = useState(0);
  const {
    className,
    rootClassName,
    intl,
    listings,
    createURLToListing,
    onListingInfoCardClicked,
    config,
  } = props;
  const currentListing = ensureListing(listings[currentListingIndex]);
  const hasCarousel = listings.length > 1;

  const classes = classNames(rootClassName || css.root, className);
  const caretClass = classNames(css.caret, { [css.caretWithCarousel]: hasCarousel });

  return (
    <div className={classes}>
      <div className={css.caretShadow} />
      <ListingCard
        clickHandler={onListingInfoCardClicked}
        urlToListing={createURLToListing(currentListing)}
        listing={currentListing}
        intl={intl}
        isInCarousel={hasCarousel}
        config={config}
      />
      {hasCarousel ? (
        <div className={classNames(css.paginationInfo, css.borderRadiusInheritBottom)}>
          <button
            className={css.paginationPrev}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length - 1) % listings.length
              );
            }}
          />
          <div className={css.paginationPage}>
            {`${currentListingIndex + 1}/${listings.length}`}
          </div>
          <button
            className={css.paginationNext}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length + 1) % listings.length
              );
            }}
          />
        </div>
      ) : null}
      <div className={caretClass} />
    </div>
  );
};

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
