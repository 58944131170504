import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { H3, H5, ModalInMobile, Page } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';
import config from '../../config';
import css from './SearchPage.module.css';
import { filterGuestsAmount, getPaginationData } from './SearchFilter.util';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const guestsAmountFilter = {
  key: 'guests',
  schemaType: 'guests',
  label: 'Guests',
  min: 0,
  max: 15,
  step: 2,
};

export class SearchPageComponent extends Component {
  constructor(props) {
    if (typeof window !== 'undefined' && window?.document) {
      document?.body?.scrollTo({
        top: 0,
      });
    }
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      filterNewParms: {},
      sortByWalker: {},
      userEmail: 'false',
      resetRouter: false,
    };

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);

    //zoom out func
    this.zoomOutBoundsIfCountryOnly = this.zoomOutBoundsIfCountryOnly.bind(this);
    this.isCountryOnly = this.isCountryOnly.bind(this);
    this.trySetUserEmail = this.trySetUserEmail.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, [
          ...defaultFiltersConfig,
          guestsAmountFilter,
        ]),
      };
      /*    &pub_roomtype=singlebedroom */
      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    } else {
      // this else is for the results after the wish entry with surrounding map
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });
      const searchParams = {
        address,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, [
          ...defaultFiltersConfig,
          guestsAmountFilter,
        ]),
      };
      history.replace(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig
    );

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        Object.values(updatedURLParams)[0] == null
          ? this.setState({ filterNewParms: {} })
          : this.setState({ filterNewParms: updatedURLParams });
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);
    let sortByStr = values == 'price' ? 'HigherPriceSort' : 'LowerPriceSort';
    let sortElbWalkerAction = { ['data-elbaction']: `click:sortByClick-${sortByStr}` };
    this.setState({ sortByWalker: sortElbWalkerAction });
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  isCountryOnly(queryString) {
    if (queryString.startsWith('?')) {
      queryString = queryString.substring(1);
    }
    const params = new URLSearchParams(queryString);
    let address = params.get('address');
    if (address) {
      address = decodeURIComponent(address);
    } else {
      return true;
    }
    const parts = address && address?.split(',').map(part => part?.trim());

    if (parts !== null) {
      return parts.length === 1;
    } else {
      return; // or return a default value for the null case
    }
  }

  zoomOutBoundsIfCountryOnly(queryString) {
    if (this.isCountryOnly(queryString)) {
      return queryString; // return the original string if not country only
    }
    const params = new URLSearchParams(
      queryString.startsWith('?') ? queryString.substr(1) : queryString
    );
    const bounds = params
      .get('bounds')
      .split(',')
      .map(Number);

    const lat1 = bounds[0];
    const lon1 = bounds[1];
    const lat2 = bounds[2];
    const lon2 = bounds[3];
    const latDiff = (lat1 - lat2) * 2; // -200% of the height
    const lonDiff = (lon1 - lon2) * 2; // -200% of the width
    const newBounds = [lat1 + latDiff, lon1 + lonDiff, lat2 - latDiff, lon2 - lonDiff].join(',');
    params.set('bounds', newBounds);

    return params.toString();
  }
  trySetUserEmail(email) {
    if (this.state.userEmail == 'false' && email != this.state.userEmail) {
      this.setState({ userEmail: email });
    }
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      config,
      history
    } = this.props;

    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)

    const locationZoomedOut =
      location.search !== '' ? this.zoomOutBoundsIfCountryOnly(location.search) : '';
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      locationZoomedOut,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      [...defaultFiltersConfig, guestsAmountFilter],
      false
    );
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );

    const availablePrimaryFilters = [
      ...customPrimaryFilters,
      ...defaultFilters,
      guestsAmountFilter,
    ];
    const availableFilters = [
      ...customPrimaryFilters,
      ...defaultFilters,
      ...customSecondaryFilters,
    ];

    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(validQueryParams, listingFieldsConfig, [
      ...defaultFiltersConfig,
      guestsAmountFilter,
    ]);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, [...customSecondaryFilters, guestsAmountFilter], [])
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
          },
          selectedSecondaryFiltersCount,
          history,
          urlQueryParams
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = pagination?.totalItems
      ? pagination.totalItems
      : listings?.length
      ? listings.length
      : 0;
    /*   searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0; */
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);
    const listingsAreLoadedSucc =
      !searchInProgress && !!(hasPaginationInfo || pagination?.paginationUnsupported);
    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <div {...this.state.sortByWalker}>
          <SortBy
            sort={validQueryParams[sortConfig.queryParamName]}
            isConflictingFilterActive={!!conflictingFilterActive}
            hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
            selectedFilters={selectedFilters}
            onSelect={this.handleSortBy}
            showAsPopup
            mode={mode}
            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
          />
        </div>
      ) : null;
    };
    const noResultsInfo = !searchInProgress ? (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    ) : null;

    const { bounds, origin } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    var elbsearchLocationData =
      validQueryParams?.address !== undefined &&
      validQueryParams?.address !== 'undefined' &&
      validQueryParams?.address !== 'null'
        ? {
            ['data-elb']: `section`,
            [`data-elb-section`]: `search-search_id:${validQueryParams?.address}`,
            ['data-elbaction']: `visible:vis destp-sinput-${validQueryParams.address}`,
          }
        : {};
    var elbDataFilter =
      Object.keys(this.state.filterNewParms).length != 0
        ? {
            ['data-elbaction']: `click:click destp-filter-${
              Object.values(this.state.filterNewParms)[0]
            }`,
          }
        : {};
    const setUserData = data => {
      if (data) {
        this.trySetUserEmail(data);
      }
    };
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        noChildrenMargin={true}
      >
        {!this.state.isMobileModalOpen ? (
          <TopbarContainer
            className={topbarClasses}
            currentPage="SearchPage"
            currentSearchParams={urlQueryParams}
            setUserEmail={setUserData}
          />
        ) : null}
        <div
          className={css.container}
          data-elbglobals="pagetype:destinations"
          data-elbcontext="booking:discover"
        >
          <div className={css.searchResultContainer} {...elbsearchLocationData}>
            <div {...elbDataFilter}>
              <SearchFiltersMobile
                className={css.searchFiltersMobileMap}
                urlQueryParams={validQueryParams}
                sortByComponent={sortBy('mobile')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                onCloseModal={this.onCloseMobileModal}
                resetAll={this.resetAll}
                selectedFiltersCount={selectedFiltersCountForMobile}
                noResultsInfo={noResultsInfo}
                isMapVariant
              >
                {availableFilters.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.key}`}
                      idPrefix="SearchFiltersMobile"
                      config={config}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      liveEdit
                      showAsPopup={false}
                    />
                  );
                })}
              </SearchFiltersMobile>
              <div
                data-elb="section"
                data-elb-section={`name:results-count-${totalItems};`}
                data-elbaction={`visible:vis results-amount-${totalItems};`}
              >
                <MainPanelHeader
                  className={css.mainPanelMapVariant}
                  sortByComponent={sortBy('desktop')}
                  isSortByActive={sortConfig.active}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  noResultsInfo={noResultsInfo}
                >

                  <SearchFiltersPrimary {...propsForSecondaryFiltersToggle}>
                    {availablePrimaryFilters.map(config => {
                      return (
                        <FilterComponent
                          key={`SearchFiltersPrimary.${config.key}`}
                          idPrefix="SearchFiltersPrimary"
                          config={config}
                          marketplaceCurrency={marketplaceCurrency}
                          urlQueryParams={validQueryParams}
                          initialValues={initialValues(this.props, this.state.currentQueryParams)}
                          getHandleChangedValueFn={this.getHandleChangedValueFn}
                          intl={intl}
                          showAsPopup
                          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        />
                      );
                    })}
                  </SearchFiltersPrimary>
                </MainPanelHeader>
              </div>
            </div>
            {isSecondaryFiltersOpen ? (
              <div className={classNames(css.searchFiltersPanel)}>
                <SearchFiltersSecondary
                  urlQueryParams={validQueryParams}
                  listingsAreLoaded={listingsAreLoaded}
                  applyFilters={this.applyFilters}
                  cancelFilters={this.cancelFilters}
                  resetAll={this.resetAll}
                  onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
                >
                  {customSecondaryFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersSecondary.${config.key}`}
                        idPrefix="SearchFiltersSecondary"
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup={false}
                      />
                    );
                  })}
                </SearchFiltersSecondary>
              </div>
            ) : (
              <div
                className={classNames(css.listingsForMapVariant, {
                  [css.newSearchInProgress]: !(listingsAreLoadedSucc || searchListingsError),
                })}
              >
                {searchListingsError ? (
                  <H3 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </H3>
                ) : null}
                {!isValidDatesFilter ? (
                  <H5>
                    <FormattedMessage id="SearchPage.invalidDatesFilter" />
                  </H5>
                ) : null}
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={listings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  setActiveListing={onActivateListing}
                  isMapVariant
                />
              </div>
            )}
          </div>
          <div data-elbaction={`wait(10):isAuthenticated:${this.state.userEmail}`}></div>
          <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper} data-testid="searchMapContainer">
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  listings={listings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              ) : null}
            </div>
          </ModalInMobile>
          {/*   </div> */}
        </div>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);

  let isDevEnv = false;
  if (typeof window !== 'undefined') {
    const hostname = window?.location?.hostname;
    isDevEnv = hostname.includes('localhost');
  }

  let filteredListingsNoTests = [];
  if (!isDevEnv) {
    listings.map(listing => {
      if (
        !(
          listing?.attributes?.publicData?.devListing &&
          listing?.attributes?.publicData?.devListing == true
        )
      ) {
        filteredListingsNoTests.push(listing);
      }
    });
  } else {
    filteredListingsNoTests = listings;
  }

  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedSearchPage);

export default SearchPage;
