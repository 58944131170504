import { FormattedMessage, useIntl } from 'react-intl';
import css from './search-bar.module.css';
import SearchIcon from '../../../Topbar/SearchIcon';
import { Form } from 'react-final-form';
import { ListingLocationField } from '../../../LocationAutocompleteInputOld/ListingsLocationField';
import { useConfiguration } from '../../../../context/configurationContext';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TopbarSearchForm from '../../../Topbar/TopbarSearchForm/TopbarSearchForm';
import { parse } from '../../../../util/urlHelpers';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../../util/routes';

export const SearchBar = props => {
  const { history, currentSearchParams } = props;
  const location = useLocation();
  const config = useConfiguration();
  const intl = useIntl();
  const [searchLocation, setSearchLocation] = useState('');

  const handleChange = e => {
    e.preventDefault();
    if (e.target?.value?.length) {
      setSearchLocation(e.target.value);
    }
  };

  const handleSubmit = useCallback(
    values => {
      const keywords = values.keywords;
      const { search, selectedPlace } = values.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const searchParams = {
        ...(keywords && keywords),
        ...currentSearchParams,
        ...originMaybe,
        address: search,
        bounds,
      };
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    },
    [history, currentSearchParams, config]
  );

  const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;

  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };
  if (location.pathname.startsWith('/s')) {
    return (
      <TopbarSearchForm
        className={css.searchLink_active}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={handleSubmit}
        initialValues={initialSearchFormValues}
        handleLocationChange={() => {}}
      />
    );
  } else {
    return (
      <Form
        className=""
        onSubmit={formValues => handleSubmit(formValues)}
        onChange={formValues => handleChange(formValues)}
        render={() => {
          return (
            <form onSubmit={handleSubmit} onChange={handleChange}>
              <div className={css.searchBar}>
                <SearchIcon className={css.searchIcon} />
                <ListingLocationField
                  className={css.searchBar}
                  inputClassName={css.searchInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  autoFocus={false}
                  name="location"
                  placeholder={intl.formatMessage({ id: 'navbar.searchButtonText' })}
                  useDefaultPredictions={false}
                  format={v => v}
                  value={data => {}}
                  onChange={e => {}}
                />
                <button type="submit" className={css.searchButton}>
                  <FormattedMessage id="navbar.searchButtonText" />
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }
};
